<template>
	<div class="app-container">
		<el-card>
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button style="float: right;" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="searchForm" ref="searchForm" size="small" label-width="100px">
					<el-form-item label="是否重做：" prop="retake">
						<el-select v-model="searchForm.retake" clearable placeholder="请选择">
						    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="套题名：" prop="name">
						<el-input v-model="searchForm.name" placeholder="请输入套题名"></el-input>
					</el-form-item>
					<el-form-item label="是否启用：" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择">
						    <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="组题类型：" prop="examType">
						<el-radio v-for="item in typeArr" v-model="searchForm.examType" :label="item.label">{{item.value}}</el-radio>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="append()">新增</el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button type="primary" size="medium" icon="el-icon-s-grid" circle slot="reference"></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1;getList(page)"></el-button>
		</el-row>
		<el-card>
			<el-table :data="tableData" :stripe="true" border v-loading="listLoading" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'retake'">{{scope.row.retake == 1 ? '允许' : '不允许'}}</span>
						<span v-else-if="item.val == 'isCreate'">{{scope.row.isCreate == 0 ? '未生成' : '已生成'}}</span>
						<span v-else-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else-if="item.val == 'examType'">{{scope.row.examType == 1 ? '手动组题' : '随机组题'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="defaultQuestionNum" label="默认题目数量" min-width="110"></el-table-column>
				<el-table-column prop="defaultScore" label="默认分值" min-width="110"></el-table-column>
				<el-table-column prop="defaultSort" label="默认考试顺序" min-width="110"></el-table-column> -->
				<el-table-column label="操作" min-width="140" fixed="right">
					<template slot-scope="scope">
						<el-button class="marginRight10" type="primary" size="mini" icon="el-icon-edit" @click="editTable(scope.$index, scope.row)">修改</el-button>
						<!-- <el-button class="marginLeft0 marginRight10 marginTop5" type="success" size="mini" icon="el-icon-document-add" @click="groupTable(scope.$index, scope.row)">{{scope.row.isCreate == 0 ? '生成组题' : '更新组题'}}</el-button> -->
						<el-switch v-model="scope.row.status" @change="switChange($event, scope.row)" active-color="#409eff" inactive-color="#ff4949" active-text="启用" inactive-text="禁用" :active-value="1" :inactive-value="0"></el-switch>
						<!-- <el-button class="marginLeft0 marginRight10 marginTop5" type="danger" size="mini" icon="el-icon-pie-chart">组卷进度</el-button> -->
					</template>
				 </el-table-column>
				<el-table-column label="套题信息" min-width="120" fixed="right">
					<template slot-scope="scope">
						<el-button type="warning" size="mini" icon="el-icon-document" :disabled="scope.row.examType == 1 ? false : true" @click="infoTable(scope.$index, scope.row)">套题信息</el-button>
					</template>
				 </el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog :title="titleInfo" :visible.sync="dialogFormVisible">
		  <el-form :model="form" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
		    <el-form-item label="套题名称：" prop="name">
		      <el-input v-model="form.name" autocomplete="off"></el-input>
		    </el-form-item>
		    <el-form-item label="是否重做：" prop="retake">
		      <el-select style="display: block;" v-model="form.retake" placeholder="请选择">
		        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item label="套题题数：" prop="num">
		      <el-input type="number" :disabled="form.examType != 2" v-model="form.num" autocomplete="off" @input="numBlur"></el-input>
		    </el-form-item>
		    <el-form-item label="组题类型：" prop="examType">
			  <el-radio v-for="item in typeArr" v-model="form.examType" :label="item.label" @change="radioChange">{{item.value}}</el-radio>
		    </el-form-item>
		    <el-form-item v-if="form.examType == 2" label="出题比例：" prop="weight">
				<el-button type="success" size="mini" @click="againBtn">{{form.weight.length == 0 ? '添加' : '重新添加'}}</el-button>
				<div class="proBox">
					<div v-for="item in form.weight">{{item.categoryName}}<span>{{item.weight}}</span></div>
				</div>
		    </el-form-item>
		    <el-form-item v-else-if="form.examType == 1" label="选择题目：" prop="questionIdList">
				<el-button type="success" size="mini" @click="subjectcilck()">选择</el-button>
				<div class="proBox">
					<!-- <div>{{form.num == '' || titleInfo == '修改' ? '请选择题目' : '已选择 ' + form.num + ' 题'}}</div> -->
					<div>{{form.num == '' ? '请选择题目' : '已选择 ' + form.num + ' 题'}}</div>
				</div>
		      <!-- <el-select style="display: block;" filterable v-model="form.problem" placeholder="请选择" :loading="proLoading">
		        <el-option v-for="item in problemArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
		      </el-select> -->
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="questionscancel('subjectForm')">取 消</el-button>
		    <el-button type="primary" @click="dialogConfirm('ruleForm')">确 定</el-button>
		  </div>
		</el-dialog>
		<el-dialog title="选择题目" :fullscreen="true" :visible.sync="subjectVisible">
			<el-card>
				<div>
					<i class="el-icon-search"></i>
					<span>查询条件</span>
					<el-button style="float: right; margin-left: 5px;" @click="searchclear('subjectForm')" size="small">清空</el-button>
					<el-button style="float: right;" type="primary" @click="subjectquery()" size="small" icon="el-icon-search">查询</el-button>
				</div>
				<div style="margin-top: 15px">
					<el-form :inline="true" :model="subjectForm" ref="subjectForm" size="small" label-width="100px">
						<el-form-item label="父分类：" prop="value1">
							<el-select v-model="subjectForm.value1" value-key="id" clearable placeholder="请选择" @change="selectchange(subjectForm.value1)">
							    <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="子分类：" prop="value2">
							<el-select v-model="subjectForm.value2" value-key="id" clearable placeholder="请选择">
							    <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="题型：" prop="value3">
							<el-select v-model="subjectForm.value3" value-key="id" clearable placeholder="请选择">
							    <el-option v-for="item in options3" :key="item.id" :label="item.text" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="关键字：" prop="key">
							<el-input v-model="subjectForm.key" placeholder="请输入关键字"></el-input>
						</el-form-item>
					</el-form>
				</div>
			</el-card>
			<el-row>
				<el-button type="warning" size="medium" @click="selsedataclean">({{subjectSelection.length}}) 清除选中</el-button>
				<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="getsubjectlist(subjectpage)"></el-button>
			</el-row>
			<el-card>
				<el-table :row-key="row => row.id" :data="subjectData" ref="subjecttable" :stripe="true" border v-loading="subjectLoading" style="width: 100%" height="450" @selection-change="subjectSelectionChange">
					<el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
					<el-table-column v-for="item in subjectArr" :label="item.label" :prop="item.val" min-width="110"></el-table-column>
				</el-table>
			</el-card>
			<el-pagination style="margin-top: 20px;" @current-change="subjectCurrentChange" :current-page="subjectpage" layout="total, prev, pager, next, jumper" :total="subjectTotal"></el-pagination>
			<div slot="footer" class="dialog-footer">
				<el-button @click="subjectVisible = false">取 消</el-button>
				<el-button type="primary" @click="subjectadd">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="出题比例" :visible.sync="ratioVisible">
			<ul class="ratioUl" v-loading="ratioLoading">
				<li v-for="(item, index) in ratioArr" :class="item.isParent ? 'li' : ''">
					<div @click="ratioSpanClick(index)">
						<span :class="ratioIndex == index ? 'ratioSpan' : ''">{{item.name}}</span>
						<input v-if="item.inputH" type="number" name="" :focus="item.inputH" v-model="item.input" autocomplete="off" @blur="inputBlur(index)" />
					</div>
				</li>
			</ul>
			<div slot="footer" class="dialog-footer">
				<el-button @click="ratioVisible = false">取 消</el-button>
				<el-button type="primary" @click="ratioConfirm">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="套题信息" :fullscreen="true" :visible.sync="quesVisible">
			<el-card>
				<div style="margin-bottom: 15px;">
					<i class="el-icon-search"></i>
					<span>查询条件</span>
					<el-button style="float: right;" @click="searchQuesTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
				</div>
				<el-form class="quesForm" :inline="true" :model="quesForm" size="small" label-width="100px">
					<el-form-item label="题目：">
						<el-input v-model="quesForm.title" placeholder="请输入关键字"></el-input>
					</el-form-item>
				</el-form>
			</el-card>
			<el-card style="margin-top: 15px;">
			<ul class="quesUl" v-loading="quesLoading">
				<li v-for="item in quesArr">
					<h2 :class="item.typeName == '单选题' ? 'colA' : 'colB'">{{item.typeName}}</h2>
					<h3>{{item.title}}</h3>
					<h4><i class="el-icon-view"></i> 答案：{{item.answer}} <span>难度：{{item.difficultLevel == 1 ? '易' : '难'}}</span></h4>
				</li>
			</ul>
			<!-- 分页 -->
			<el-pagination @current-change="quesChange" :current-page="pages" layout="total, prev, pager, next, jumper" :total="quesTotal"></el-pagination>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				page: 1,
				total: 0,//分页总条数
				searchForm: {
					retake: '',
					examType: '',
					status: 1,
					name: ''
				},
				options: [{
					value: 1,
					label: "允许"
				}, {
					value: 2,
					label: "不允许"
				}],//是否重做
				statusArr: [{
					value: 0,
					label: "禁用"
				}, {
					value: 1,
					label: "启用"
				}],//是否启用
				createArr: [{
					value: 0,
					label: "未生成"
				}, {
					value: 1,
					label: "已生成"
				}],//是否生成套题
				typeArr: [{
					value: "手动组题",
					label: 1
				}, {
					value: "随机组题",
					label: 2
				}],//组卷类型
				visible: false,//多选框
				checkArr: [{
					label: "套题名称", val: "name", dis: true,
				}, {
					label: "题目数量", val: "questionNum", dis: true,
				}, {
					label: "是否启用", val: "status", dis: true,
				}, {
					label: "是否重做", val: "retake", dis: true,
				}, {
					label: "组题类型", val: "examType", dis: true,
				}, {
					label: "是否组题", val: "isCreate", dis: true,
				}],//表格循环内容
				checkList: ["name", "questionNum", "status", "retake", "examType", "isCreate"],//选择表格显示内容
				tableData: [],
				titleInfo: '',
				dialogFormVisible: false,//修改-弹窗
				formLabelWidth: '120px',//修改弹窗-label宽度
				form: {
					id: '',
					name: '',
					retake: '',
					examType: '',
					num: '',
					weight: [],//出题比例数组
					problem: [],
					questionIdList: []//题目id数组
				},//修改-表单内容
				rules: {
					name: [{ required: true, message: '请输入套题名称', trigger: 'blur' }],
					retake: [{ required: true, message: '请选择是否重做', trigger: 'blur' }],
					examType: [{ required: true, message: '请选择组题类型', trigger: 'blur' }],
					num: [{ required: true, message: '请输入套题题数', trigger: 'blur' }],
					weight: [{ required: true, message: '请选择出题比例', trigger: 'blur' }],
					questionIdList: [{ required: true, message: '请选择题目', trigger: 'blur' }]
				},//修改-表单验证	
				subjectVisible: false,//选择题目弹窗
				subjectLoading: false,//题目表格loading
				subjectForm: {
					key: '',
					value1: '',
					value2: '',
					value3: ''
				},//题目查询
				options1: [],
				options2: [],
				options3: [],
				subjectData: [],//题目表格
				subjectSelection: [],//表格选中
				subjectId: '',
				subjectArr: [
					{label: '题型', val: 'typeName'},
					{label: '题目', val: 'title'},
					{label: '答案', val: 'answer'}
				],
				subjectTotal: 0,//题目-条目总数
				subjectpage: 1,//题目-分页
				ratioVisible: false,//出题比例-弹窗	
				ratioArr: [],//出题比例数组
				ratioLoading: false,
				ratioIndex: -1,//出题比例选中index
				problemArr: [],//套题选择
				proLoading: false,//套题数组加载
				infoId: '',//套题id-套题信息内使用
				quesVisible: false,//套题信息-弹窗
				quesLoading: false,//套题信息loading
				quesForm: {
					title: ''
				},//套题信息顶部
				pages: 1,//套题信息列表-页码
				quesArr: [],//套题信息列表
				quesTotal: 0,//套题信息-分页总数
			}
		},
		components: {
			
		},
		created(){
			this.getList(1);
		},
		methods:{
			getList(page) {
				this.listLoading = true;
				let data = {
					pageIndex: page,
					pageSize: 10,
					name: this.searchForm.name,
					type: '',
					examType: this.searchForm.examType,
					retake: this.searchForm.retake,
					examinationPerson: '',
					isCreate: '',
					status: this.searchForm.status
				}
				this.$comjs.ajax.getAjax('/fa/exam/paper/grid/list', data, this, res => {
					this.tableData = res.res;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			// 查询
			searchTable() {
				// console.log(this.searchForm)
				this.page = 1;
				this.getList(this.page);
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.page = 1;
				this.getList(this.page);
			},
			// 新增
			append() {
				this.form = {
					id: '',
					name: '',
					retake: '',
					examType: '',
					num: '',
					weight: [],
					problem: [],
					questionIdList: []
				}
				this.titleInfo = "新增";
				this.dialogFormVisible = true;
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			},
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 题目查询
			subjectquery() {
				this.subjectpage = 1
				this.getsubjectlist(this.subjectpage)
			},
			// 题目查询-重置
			searchclear(formName) {
				this.$refs[formName].resetFields();
			},
			// 获取分类信息
			getclassify(id, options) {
				let data = {
					pId: id
				}
				this.$comjs.ajax.postAjax('/fa/exam/category/parent/list', data, this, res => {
					this[options] = res.list
				})
			},
			// 下拉框值改变
			selectchange(id) {
				this.subjectForm.value2 = ''
				this.getclassify(id, 'options2')
			},
			// 获取题型分类
			getquestiontype() {
				let data = {}
				this.$comjs.ajax.postAjax('/fa/questionsType/select2', data, this, res => {
					this.options3 = res.list
				})
			},
			// 题目列表
			getsubjectlist(subjectpage) {
				this.subjectVisible = true;
				this.subjectLoading = true;
				if(this.titleInfo == "新增") {
					let data = {
						parentCategoryId: this.subjectForm.value1,
						categoryId: this.subjectForm.value2,
						typeId: this.subjectForm.value3,
						title: this.subjectForm.key,
						sortCol: '',
						sortType: 'asc',
						pageIndex: subjectpage,
						pageSize: 10,
						examId: 'FaExamQuestions'
					}
					this.$comjs.ajax.getAjax('/fa/exam/questions/grid/list', data, this, res => {
						this.subjectData = res.res;
						this.subjectTotal = res.total;
						this.subjectLoading = false;
						this.$refs.subjecttable.clearSelection();
					})
					return false;
				}
				let datas = {
					examId: this.infoId,
					title: this.quesForm.title,
					pageIndex: 1,
					pageSize: 999
				}
				this.$comjs.ajax.getAjax('/fa/exam/paper/detail/list', datas, this, res => {
					this.quesArr = res.list;
					let data = {
						parentCategoryId: this.subjectForm.value1,
						categoryId: this.subjectForm.value2,
						typeId: this.subjectForm.value3,
						title: this.subjectForm.key,
						sortCol: '',
						sortType: 'asc',
						pageIndex: subjectpage,
						pageSize: 10,
						examId: 'FaExamQuestions'
					}
					this.$comjs.ajax.getAjax('/fa/exam/questions/grid/list', data, this, res => {
						this.subjectData = res.res;
						this.subjectTotal = res.total;
						this.subjectLoading = false;
						this.$nextTick(() => {
							if (this.quesArr.length > 0) {
								this.subjectData.forEach(item => {
									this.quesArr.forEach(row => {
										if(item.id == row.id) {
											this.$refs.subjecttable.toggleRowSelection(item, true);
										}
									});
								})
							} else {
								this.$refs.subjecttable.clearSelection();
							}
						})
					})
				});
			},
			// 选择题目
			subjectcilck() {
				this.subjectpage = 1
				this.getsubjectlist(this.subjectpage)
				this.getclassify(0, 'options1')
				this.getquestiontype()
			},
			// 选择题目-确定
			subjectadd() {
				let comments = this.subjectSelection
				let num = []
				for (let i = 0;i<comments.length;i++){
					num.push(comments[i].id)
				}
				this.form.questionIdList = num
				// let names = []
				// for (let i = 0;i<comments.length;i++){
				// 	names.push(comments[i].categoryName)
				// }
				// let name = []
				// for (let i = 0;i<names.length;i++){
				// 	if (names.indexOf(names[i]) === i) {
				// 		name.push(names[i])
				// 	}
				// }
				//console.log(this.form.questionIdList)
				this.form.num = this.form.questionIdList.length
				this.subjectVisible = false
			},
			// 题目表格选中
			subjectSelectionChange(val) {
				this.subjectSelection = val
			},
			// 题目表格清空选中
			selsedataclean() {
				this.$refs.subjecttable.clearSelection()
			},
			// 题目表格分页
			subjectCurrentChange(val) {
				this.subjectpage = val
				this.getsubjectlist(this.subjectpage)
			},
			// 获取套题信息
			getInfo(page) {
				this.quesLoading = true;
				let data = {
					examId: this.infoId,
					title: this.quesForm.title,
					pageIndex: page,
					pageSize: 10
				}
				this.$comjs.ajax.getAjax('/fa/exam/paper/detail/list', data, this, res => {
					this.quesArr = res.list;
					this.quesTotal = res.total;
					this.quesLoading = false;
				});
			},
			// 套题信息
			infoTable(index, row) {
				this.quesVisible = true;
				this.infoId = row.id;
				this.pages = 1;
				this.getInfo(this.pages);
			},
			// 套题信息-查询
			searchQuesTable() {
				this.pages = 1;
				this.getInfo(this.pages);
			},
			// 套题信息-分页
			quesChange(val) {
				this.pages = val;
				this.getInfo(this.pages);
			},
			// 表格-生成/更新组题
			groupTable(index, row) {
				if (row.examType == 2) {
					let isCreate = row.isCreate;
					let data = {
						examId: row.id
					}
					if(isCreate) {//更新
						this.$comjs.ajax.postAjax('/fa/exam/paper/reCreate', data, this, res => {
							this.$message({
								message: res.message,
								type: 'success'
							});
							this.getList(this.page);
						});
					} else {//生成
						this.$comjs.ajax.postAjax('/fa/exam/paper/changeIsCreate', data, this, res => {
							this.$message({
								message: res.message,
								type: 'success'
							});
							this.getList(this.page);
						});
					}
				}
				else {
					this.$message({
						message: '已生成题目！',
						type: 'success'
					});
				}
			},
			// 表格-是否启用
			switChange(val, row) {
				let data = {
					id: row.id,
					status: val,
					entity: 'FaExamExaminationPaper'
				}
				this.$comjs.ajax.postAjax('/jqGrid/changeStatus', data, this, res => {
					this.$message({
						message: res.message,
						type: 'success'
					});
					this.getList(this.page);
				});
			},
			// 表格修改
			editTable(index, row) {
				this.infoId = row.id;
				// console.log(index, row)
				let data = {
					examId: row.id
				}
				this.$comjs.ajax.postAjax('/fa/exam/paper/findOne', data, this, res => {
					this.form = {
						id: res.data.id,
						name: res.data.name,
						retake: res.data.retake,
						examType: res.data.examType,
						num: res.data.questionNum,
						weight: row.examType == 1 ? [] : res.data.weight,
						problem: res.data.examTempletId,
						questionIdList: []
					}
					if(this.form.examType == 1) {
						// this.getPaper()
					}
					this.titleInfo = "修改";
					this.dialogFormVisible = true;
				});
			},
			// 修改/新增-确定
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.form.examType == 1) {
							var data = {
								id: this.form.id,
								name: this.form.name,
								retake: this.form.retake,
								questionNum: this.form.num,
								examType: this.form.examType,
								questionIdList: this.form.questionIdList
							}
							console.log(this.form.questionIdList)
						}
						else {
							let obj = {
								id: this.form.id,
								name: this.form.name,
								retake: this.form.retake,
								weightSum: this.form.num,
								questionNum: this.form.num,
								examType: this.form.examType,
								examTempletId: this.form.problem
							}
							let arr = [];
							this.form.weight.forEach(item => {
								let objs = {
									categoryId: item.categoryId,
									weight: item.weight
								}
								arr.push(objs)
							})
							var data = {
								paperJson: JSON.stringify(obj),
								weightJson: JSON.stringify(arr)
							}
							console.log(data)
						}
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							if (this.form.examType == 1) {
								this.$comjs.ajax.postJsonAjax('/fa/exam/paper/save', data, this, res => {
									this.$refs.subjecttable.clearSelection();
									this.dialogFormVisible = false;
									this.$refs[formName].resetFields();
									this.$message({	
										message: '保存成功！',
										type: 'success',
										duration: 1000
									});
									this.getList(this.page);
								})
							}
							else {
								this.$comjs.ajax.postAjax('/fa/exam/paper/addOrEdit', data, this, res => {
									this.dialogFormVisible = false;
									this.$refs[formName].resetFields();
									this.$message({	
										message: '保存成功！',
										type: 'success',
										duration: 1000
									});
									this.getList(this.page);
								});
							}		
						}).catch(() => {
							
						});
					}
				});
			},
			// 新增修改弹窗-取消
			questionscancel(formName) {
				this.dialogFormVisible = false
				this.subjectForm.key = ''
				this.subjectForm.value1 = ''
				this.subjectForm.value2 = ''
				this.subjectForm.value3 = ''
				if (this.subjectSelection.length > 0) {
					this.$refs.subjecttable.clearSelection();
				}
			},
			// 获取套题数组
			getPaper() {
				this.proLoading = true;
				let data = {
					questionNum: this.form.num
				}
				this.$comjs.ajax.getAjax('/fa/exam/paper/findQuestionNumPaper', data, this, res => {
					this.problemArr = res.list;
					this.proLoading = false;
				});
			},
			// 套卷题数
			numBlur() {
				if(this.form.examType == 1) {//手动组题
					if(this.form.num > 0) {
						this.getPaper();
					}
				} else {//随机组题
					this.form.weight = [];
				}
			},
			// 修改-组卷类型
			radioChange(val) {
				console.log(val)
				this.$refs.ruleForm.clearValidate();
				if(val == 1) {//手动组题
					this.form.num = this.form.questionIdList.length == 0 ? '' : this.form.questionIdList.length;
				} else {//随机组题
					this.form.num = '';
					this.form.weight = [];
				}
				// if(val == 1) {
				// 	if(this.form.num > 0) {
				// 		this.getPaper();
				// 	} else {
				// 		this.$message({
				// 			message: '请输入套题题数！',
				// 			type: 'warning'
				// 		});
				// 		this.form.examType = '';
				// 	}
				// }
			},
			// 修改-重新添加
			againBtn() {
				if(this.form.num > 0) {
					this.ratioLoading = true;
					this.ratioVisible = true;
					this.ratioIndex = -1;
					this.$comjs.ajax.postAjax('/fa/exam/category/tree/question/list', "", this, res => {
						this.ratioArr = res.list;
						this.ratioLoading = false;
						if(this.form.weight.length > 0 && this.ratioArr.length > 0) {
							this.form.weight.forEach(item => {
								this.ratioArr.forEach(items => {
									if(item.categoryId == items.id) {
										items.input = item.weight;
										items.inputH = true;
									}
								})
							})
						}
					});
				} else {
					this.$message({
						message: '请输入套题题数！',
						type: 'warning'
					});
				}
			},
			// 出题比例选中
			ratioSpanClick(index) {
				if(!this.ratioArr[index].isParent) {
					if(this.ratioIndex != -1) {
						if(this.ratioArr[this.ratioIndex].input == 0 || this.ratioArr[this.ratioIndex].input == '') {
							this.ratioArr[this.ratioIndex].inputH = false;
							this.ratioArr[this.ratioIndex].input = '';
						}
					}
					this.ratioIndex = index;
					if(!this.ratioArr[index].inputH) {
						this.ratioArr[index].inputH = true;
						this.ratioArr[this.ratioIndex].input = '';
					}
				}
			},
			inputBlur(index) {console.log(index)
				let obj = this.ratioArr[index];
				if(parseInt(obj.input) > parseInt(obj.remark)) {
					this.$message({
						message: obj.name + '超出总题目数请修改！',
						type: 'warning'
					});
				}
				if(obj.input == 0 || obj.input == '') {
					this.ratioArr[index].inputH = false;
					this.ratioArr[index].input = '';
				}
			},
			// 出题比例-确定
			ratioConfirm() {
				let num = 0;
				let bool = true;
				let arrs = [];
				this.ratioArr.forEach(item => {
					if(item.inputH) {
						if(parseInt(item.input) > parseInt(item.remark)) {
							bool = false;
						}
						// let str = item.name;
						// let str1 = str.substring(0,str.indexOf(" "));
						if(item.input == 0 || item.input == '') {
							item.inputH = false;
							item.input = '';
						} else {
							let obj = {
								categoryId: item.id,
								categoryName: item.name,
								weight: item.input
							}
							arrs.push(obj);
							num = num + parseInt(item.input ? item.input : 0);
						}
					}
				})
				if(bool) {
					if(this.form.num != num) {
						this.$message({
							message: '出题的题数必须和套题题数' + this.form.num + '一致！',
							type: 'warning'
						});
					} else {
						this.ratioVisible = false;
						this.form.weight = arrs;
						this.ratioIndex = -1;
					}
				} else {
					this.$message({
						message: '出题的题数必须和套题题数有误，请修改！',
						type: 'warning'
					});
				}
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList(val);
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.elCard {
		margin-top: 20px;
	}
	.proBox {
		min-height: 20px;
		padding: 0 10px;
		border: 1px solid #DCDFE6;
	}
	.proBox div {
		width: 50%;
	}
	.proBox div span {
		float: right;
		color: #f55;
	}
	.ratioUl {
		margin: 0;
	}
	.ratioUl li {
		padding: 10px 0;
	}
	.ratioUl .li {
		font-weight: bold;
	}
	.ratioUl li span {
		cursor: pointer;
	}
	.ratioUl .ratioSpan {
		color: #409EFF;
	}
	.ratioUl input {
		width: 50px;
		margin-left: 20px;
		font-size: 12px;
		outline: none;
	}
	/*在谷歌下移除input[number]的上下箭头*/
	.ratioUl input[type='number']::-webkit-outer-spin-button,
	.ratioUl input[type='number']::-webkit-inner-spin-button{
	    -webkit-appearance: none !important;
	}
	/*在firefox下移除input[number]的上下箭头*/
	.ratioUl input[type="number"]{-moz-appearance:textfield;}
	.quesUl {
		margin: 0;
		padding: 0;
	}
	.quesUl li {
		margin: 0 0 10px;
		padding: 10px;
		border: 1px solid #e7eaec;
		border-radius: 2px;
		background: #FAFAFB;
		line-height: 20px;
		list-style: none;
		cursor: pointer;
	}
	.quesUl li:hover {
		background-color: #fff;
	}
	.quesUl li h2 {
		margin: 0 0 5px;
		font-weight: 400;
		font-size: 14px;
	}
	.colA {
		color: #409EFF;
	}
	.colB {
		color: #f8ac59;
	}
	.quesUl li h3 {
		margin: 0;
		font-weight: 400;
		font-size: 14px;
	}
	.quesUl li h4 {
		margin: 0;
		font-weight: 400;
		font-size: 12px;
	}
	.quesUl li h4 span {
		float: right;
	}
</style>

